import { Share } from '@mui/icons-material'
import React from 'react'
import './share-button.css'
const ShareButton = (props) => {
  return (
    <div className='view-button-span d-inline-block' onClick={props.onClick()}> 
      <Share/>
    </div>
  )
}

export default ShareButton