import React, { useState } from 'react'
import LeftArrow from '../left-arrow/LeftArrow'

const LeftHyperlinkButton = (props) => {
    let [textColor, setTextColor] = useState(props.textColor);
    return (
        <a href={props.href} style={{ display: 'inline-block', textDecoration: 'none' }} onMouseOver={(e) => setTextColor(props.hoverTextColor)} onMouseOut={(e) => setTextColor(props.textColor)}>
            <div className='d-flex align-items-center justify-content-center'>
                <span><LeftArrow /></span>
                <span>&nbsp;&nbsp;</span>
                <span style={{ color: textColor }}>{props.text}</span>
            </div>
        </a>
    )
}

export default LeftHyperlinkButton