import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer/footer'
import NavBar from '../../components/navbar/NavBar'
import { Alert, Checkbox, FormControlLabel, Radio, RadioGroup, Snackbar, TextField } from '@mui/material'
import { text, mediaByName } from '../../services/pageFunctions';
import { getPageBySlug } from '../../services/pageService'
import { useViewport } from '../../hooks/viewport-provider'
import { Controller, useForm } from 'react-hook-form';
import { createMessage } from '../../services/messageService';
import { addSubscriber } from '../../services/subscriberService';
import { Helmet } from 'react-helmet-async';
import ChatPopup from '../../components/chat-popup/ChatPopup'

const Contact = () => {
  const [page, setPage] = useState(null);
  const { width } = useViewport();
  const { control, handleSubmit } = useForm();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveFailed, setSaveFailed] = useState(false);

  const onSubmit = (data) => {
    createMessage(data).then(result => {
      if (data.subscribe) {
        addSubscriber(data.senderEmail, data.senderName)
      }
      setSaveSuccess(true);
    }).catch(err => {
      setSaveFailed(true);
    })
  }

  const handleClose = () => {
    setSaveSuccess(false);
    setSaveFailed(false);
  }

  useEffect(() => {
    getPageBySlug('contact').then(result => {
      setPage(result.data.data);
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>SASHA | Contact Us</title>
        <meta name='title' content="Contact Us | PoSH consultation and compliance" />
        <meta name='description' content="Partner with us to create safe & inclusive workplaces. Get in touch for customized training sessions, PoSH policy, IC constitution, compliance, filing, external member service etc." />
      </Helmet>
      <Snackbar open={saveSuccess} key={1} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Your message was posted successfully
        </Alert>
      </Snackbar>
      <Snackbar open={saveFailed} key={2} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Message could not be posted. Please write to us on our email mentioned in the page below.
        </Alert>
      </Snackbar>
      <div>
        <NavBar />
        <div className='container'>
          <div className='pt-5'></div>
          <div className='row'>
            <div className='col-sm-5 d-flex align-items-center justify-contents-center'>
              <div>
                <p className='text-danger'>{text(page, 0)}</p>
                <div className='heading'>{text(page, 1)}</div>
                <p>{text(page, 2)}</p>
              </div>
            </div>
            <div className='col-sm-7 text-center'>
              <img src={mediaByName(page, 'contact')} style={{ maxWidth: '100%' }} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='pt-5 pb-5'></div>
        <div className='container'>
          <div className='text-danger'>{text(page, 3)}</div>
          <div className='heading'>{text(page, 4)}</div>
        </div>
        <div className='pt-5'></div>
      </div>
      <div className='container pb-5'>
        <div className='rounded bg-white border-1 shadow pt-5 pb-5'>
          <form onSubmit={handleSubmit(onSubmit)} className='row mt-5 mb-5'>
            <div className='col-sm-2'>
              <img style={{maxWidth: '100%'}} src={mediaByName(page, 'mailbox')} />
            </div>
            <div className='col-sm-5 p-5'>
              <div className='text-danger mb-3'><strong>Send us a message</strong></div>
              <div className='mb-4'>
                <Controller name="senderName" control={control} render={({ field }) => <TextField label="Name" variant="standard" placeholder='Enter your name here' style={{ width: '100%' }} {...field} />} />
              </div>
              <div className='mb-4'>
                <Controller name='senderEmail' control={control} render={({ field }) => <TextField label='Email' variant='standard' placeholder='Enter your E-mail ID here' {...field} style={{ width: '100%' }} />} />
              </div>
              <div className='mb-4'>
                <Controller name='senderPhone' control={control} render={({ field }) => <TextField label='Phone Number' variant='standard' placeholder='+91 0000 0000 00' style={{ width: '100%' }} {...field} />} />
              </div>
              <div className='mb-4'>
                <Controller name='senderCompany' control={control} render={({ field }) => <TextField label='Company' variant='standard' placeholder='Your company name here' style={{ width: '100%' }} {...field} />} />
              </div>
            </div>
            <div className='col-sm-5 p-5'>
              <br />
              <br />
              <div><strong>Preferred Mode of communication</strong></div>
              <RadioGroup defaultValue='Phone'>
                <div className='d-flex align-items-center' style={{ gap: '50px' }}>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name='senderContactPreference'
                    render={({ field }) =>
                      <RadioGroup {...field}>
                        <FormControlLabel value="Phone" control={<Radio />} label="Phone" />
                        <FormControlLabel value="Email" control={<Radio />} label="Email" />
                      </RadioGroup>
                    } />
                </div>
              </RadioGroup>
              <div className='mb-4'>
                <Controller control={control} name='message' render={({ field }) => <TextField label='Message' placeholder='Message to be no more than 300 characters' multiline={true} variant='standard' rows='4' style={{ width: '100%' }} {...field} />} />
              </div>
              <div className='mb-4'>
                <Controller name='subscribe' control={control} render={({ field }) => <FormControlLabel control={<Checkbox />} label="Subscribe to Sasha Newletter" {...field} />} />
              </div>
              <button className='btn btn-danger rounded-pill ps-5 pe-5'>SUBMIT</button>
            </div>
          </form>
          <div className='mb-5'></div>
          <div className='row'>
            <div className='col-sm-2'></div>
            <div className='col-sm-5 p-5'>
              <div className='text-danger mb-5'><strong>Send us a message</strong></div>
              <div>
                <img src={mediaByName(page, 'mailIcon')} /> <span>Kanti Joshi</span>
              </div>
              <div className='mb-4 ms-4'><strong>Kanti@sashaindia.com</strong></div>
              <div>
                <img src={mediaByName(page, 'phoneIcon')} /> <span>Kanti Joshi</span>
              </div>
              <div className='mb-4 ms-4'><strong>+91 98450 96503</strong></div>
              <div>
                <img src={mediaByName(page, 'locationIcon')} /> <span>SASHA</span>
              </div>
              <div className='mb-4 ms-4'><strong>Bangalore</strong></div>
            </div>
            <div className='col-sm-5'>
              <img src={mediaByName(page, 'call')} style={{ maxWidth: '100%' }} />
            </div>
          </div>
        </div>
        <div className='mt-3'></div>
      </div>
      <Footer backgroundColor='#000000' textColor='#ffffff' />
      <ChatPopup></ChatPopup>
    </div>
  )
}

export default Contact