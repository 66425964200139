import React, { useEffect, useState } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import Footer from '../../components/footer/footer';
import NavBar from '../../components/navbar/NavBar';
import { getPageBySlug } from '../../services/pageService';
import './privacy-policy.css'
import ChatPopup from '../../components/chat-popup/ChatPopup'

const PrivacyPolicy = () => {
    const [page, setPage] = useState(null);

    useEffect(() => {
        const fn = async () => {
            let result = await getPageBySlug('privacy-policy');
            setPage(result.data.data);
        };
        fn();
    }, []);

    return (
        <div>
            <div>
                <NavBar />
            </div>
            <div className='container'>
                <div className='p-3'></div>
                {page?.textEntries.map(textEntry => <ReactMarkdown children={textEntry.text} components={{
                    p: ({ children }) => <p className='generated'>{children}</p>,
                    h1: ({ children }) => <h1 className='heading'>{children}</h1>
                }} />)}
                <div className='p-3'></div>
            </div>
            <div className='p-3'></div>
            <Footer backgroundColor='#000000' textColor='#ffffff' />
            <ChatPopup></ChatPopup>
        </div>
    )
}

export default PrivacyPolicy