import React, { useEffect, useState } from 'react'
import NavBar from '../../components/navbar/NavBar';
import './profile.css'
import linkedinBlue from '../../assets/images/linkedin-blue.png';
import downloadIcon from '../../assets/images/download-icon.png';
import Footer from '../../components/footer/footer';
import { getAllMembers, getMemberByNickname } from '../../services/memberService';
import { useParams } from 'react-router-dom';
import { mediaUrl, text } from '../../services/pageFunctions';
import { getPageBySlug } from '../../services/pageService';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import RightButton from '../../components/right-button/RightButton';
import LeftButton from '../../components/left-button/LeftButton';
import ChatPopup from '../../components/chat-popup/ChatPopup'

const Profile = () => {
  const [members, setMembers] = useState([]);
  const [member, setMember] = useState(null);
  const [page, setPage] = useState(null);
  const [minMember, setMinMember] = useState(0);
  const [maxMember, setMaxMember] = useState(5);
  let { nickname } = useParams();

  useEffect(() => {
    getAllMembers().then(result => {
      setMembers(result.data.data);
    });
    getMemberByNickname(nickname).then(result => {
      setMember(result.data.data[0]);
    });
    getPageBySlug('profile').then(result => {
      setPage(result.data.data);
    });
  }, []);

  const onNext = () => {
    setMaxMember(maxMember + 1);
    setMinMember(minMember + 1);
  }
  const onPrevious = () => {
    setMinMember(minMember - 1);
    setMaxMember(maxMember - 1);
  }

  return (
    <div>
      <NavBar />
      <div className='p-4'></div>
      <div className='container'>
        <p className='text-danger'>{text(page, 0)}</p>
        <div className='heading'>{text(page, 1)}</div>
        <p>{text(page, 2)}</p>
        <p><strong className='text-danger'>{text(page, 3)}</strong></p>
        <p>{text(page, 4)}</p>
      </div>
      <div className='p-3'></div>
      <div className='container'>
        <div className='d-flex justify-content-end'>
          {members.length > 6 && minMember > 0 ? <LeftButton onClick={onPrevious} /> : <></>}
          {members.length > 6 && maxMember < (members.length - 1) ? <RightButton onClick={onNext} /> : <></>}
        </div>
        <div className='row'>
          {members.filter((member, i) => i >= minMember && i <= maxMember).map((member, i) => (<div onClick={(e) => setMember(members[i + minMember])} className='col-sm-2 clickable-profile' key={i}>
            <div className='rounded bg-dark pt-5 pb-5 mb-3' style={{ backgroundImage: `url('${mediaUrl(member?.mediumImage[0]._id)}')`, backgroundSize: 'cover' }}><div className='pt-5 pb-5'></div></div>
            <h5 className='stylish'>{member.name}</h5>
            <div>{member.position}</div>
          </div>))}
        </div>
      </div>
      <div className='p-4'></div>
      <div className='container'>
        <div className='large-image-container' style={{ backgroundImage: `url('${mediaUrl(member?.largeImage[0]._id)}')` }}></div>
        <div className='p-5'></div>
        <div style={{ paddingLeft: '40px' }}>
          <div className='bg-dark p-4' style={{ borderRadius: '60px' }}>
            <h3 className='text-white stylish'>{member?.name}</h3>
            <div className='text-white'>{member?.position}</div>
          </div>
        </div>
      </div>
      <div className='p-4'></div>
      <div className='container'>
        <div className='d-flex'>
          <a href={member?.linkedInLink} target='_blank'>
            <img src={linkedinBlue} />
            <div className='text-dark pt-2' style={{ fontSize: 'x-small' }}>Linkedin</div>
          </a>
          {/* <div className='p-4'></div>
          <a href='#'>
            <img src={downloadIcon} />
            <div className='text-dark pt-2' style={{ fontSize: 'x-small' }}>Download</div>
          </a> */}
        </div>
      </div>
      <div className='container'>
        <div className='p-3'></div>
        <ReactMarkdown children={member?.introduction} />
        <div className='p-3'></div>
        {member?.coreSkills && member?.coreSkills !== '' ? <><h4 className='stylish ps-3' style={{ borderLeft: '5px solid #ff0000' }}>Core Skills</h4>
        <div className='p-3'></div>
        <ReactMarkdown children={member?.coreSkills} />
        <div className='p-3'></div></>: <></>}
        {member?.background && member?.background !== '' ? <><h4 className='stylish ps-3' style={{ borderLeft: '5px solid #ff0000' }}>Background</h4>
        <div className='p-3'></div>
        <ReactMarkdown children={member?.background} />
        <div className='p-3'></div></>: <></>}
        {member?.testimonials && member?.testimonials !== '' ? <><h4 className='stylish ps-3' style={{ borderLeft: '5px solid #ff0000' }}>Testimonials</h4>
        <div className='p-3'></div>
        <ReactMarkdown children={member?.testimonials} />
        <div className='p-3'></div></>: <></>}
      </div>
      <div className='p-5'></div>
      <Footer backgroundColor='#000000' textColor='#ffffff' />
      <ChatPopup></ChatPopup>
    </div>
  )
}

export default Profile