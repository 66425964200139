import React, { useState } from 'react'
import './right-button.css'
import rightButton from '../../assets/images/right-button.png'

const RightButton = (props) => {
  let [ textColor, setTextColor ] = useState(props.textColor);

  return (
    <div className='button-container' onMouseOver={(e) => setTextColor(props.hoverTextColor)} onMouseOut={(e) => setTextColor(props.textColor)} >
      <span className='d-flex align-items-center hyperlink' onClick={props.onClick}>
        <p style={{ marginBottom: '10px', color: textColor }}>{props.label}</p>
        <img src={rightButton} className={props.className} />
      </span>
    </div>
  )
}

export default RightButton