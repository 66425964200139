const axios = require('axios');

const getClientByName = async (name) => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/client/name/${name}`);
  }
  catch (err) {
    console.log('Fetching client details failed');
    return null;
  }
}

const getAllClients = async () => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/client/all`);
  }
  catch (err) {
    console.log(`Fetching all clients failed`);
    return [];
  }
}

export { getClientByName, getAllClients };
