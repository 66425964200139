import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/logo.png'
import { getAllPages } from '../../services/pageService';
import HamburgerIcon from '../hamburger-icon/HamburgerIcon';

const NavBar = () => {
  const [current, setCurrent] = useState('home');
  const [pages, setPages] = useState([]);

  useEffect(() => {
    getAllPages().then(result => {
      setPages(result.data.data);
    });
  }, []);

  return (
    <div className='shadow'>
      <div className='container'>
        <nav className="navbar navbar-expand-lg bg-transparent">
          <div className="container-fluid align-items-center justify-content-between">
            <a className="navbar-brand" href="/"><img src={logo} style={{ height: '60px' }} /></a>
            <span className="navbar-toggler border-0" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <HamburgerIcon />
            </span>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {pages.filter(page => page.active).sort((a, b) => a.order > b.order).map((page, i) => (<li key={i} className="nav-item">
                  <a className="nav-link" href={`/${page.slug}`}>{page.name}</a>
                </li>))}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default NavBar