import { Download } from '@mui/icons-material'
import React from 'react'
import './download-button.css'
const DownloadButton = (props) => {
  return (
    <div className='view-button-span d-inline-block' onClick={props.onClick()}> 
      <Download/>
    </div>
  )
}

export default DownloadButton