import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../../components/footer/footer';
import NavBar from '../../components/navbar/NavBar';
import NewLabel from '../../components/new-label/NewLabel';
import RightButton from '../../components/right-button/RightButton';
import { getArticleBySlug, getLatestArticles } from '../../services/blogService';
import { formatDate, formatDateString } from '../../services/dateUtil';
import { mediaUrl } from '../../services/pageFunctions';
import shareIcon from '../../assets/images/share-icon.png';
import linkIcon from '../../assets/images/link-icon.png';
import './article.css';
import LeftButtonWithLabel from '../../components/left-button-with-label/left-button-with-label';
import LeftHyperlinkButton from '../../components/left-hyperlink-button/LeftHyperlinkButton';
import ChatPopup from '../../components/chat-popup/ChatPopup';

const Article = () => {
  const [currentTab, setCurrentTab] = useState('recent');
  const [currentArticle, setCurrentArticle] = useState(null);
  const [latestArticles, setLatestArticles] = useState([]);
  const [isNew, setIsNew] = useState(false);
  let { slug } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);

  useState(() => {
    getArticleBySlug(slug).then(result => {
      let createdDate = Date.parse(result.data.data[0].createdAt);
      let currentDate = new Date();
      if (currentDate.getTime() - createdDate <= (1000 * 60 * 60 * 24 * 7)) {
        setIsNew(true);
      }
      result.data.data[0].createdAt = formatDateString(result.data.data[0].createdAt);
      setCurrentArticle(result.data.data[0]);
    });
    getLatestArticles().then(result => {
      result.data.data.forEach(article => {
        formatDate(article);
      });
      setLatestArticles(result.data.data);
    })
  }, []);

  const previousPage = () => {
    if (currentIndex - 4 >= 0) {
      setCurrentIndex(currentIndex - 4);
    }
  }

  const nextPage = () => {
    if (currentIndex + 4 <= latestArticles.length - 1) {
      setCurrentIndex(currentIndex  + 4);
    }
  }

  return (
    <div>
      <NavBar />
      <div className='bg-dark'>
        <div className='container' style={{ overflowX: 'auto' }}>
          <div className='text-white top-nav'>
            <a href="/blog" className={currentTab === 'recent' ? 'active' : ''} onClick={() => setCurrentTab('recent')}>RECENT</a>
            <a href="/blog/category/workplace" className={currentTab === 'workplace' ? 'active' : ''} onClick={() => setCurrentTab('workplace')}>WORKPLACE</a>
            <a href="/blog/category/policies" className={currentTab === 'policies' ? 'active' : ''} onClick={() => setCurrentTab('policies')}>POLICIES</a>
            <a href="/blog/category/law-mandates" className={currentTab === 'law-mandates' ? 'active' : ''} onClick={() => setCurrentTab('law-mandates')}>LAW MANDATES</a>
            <a href="/blog/category/internal-committee" className={currentTab === 'internal-committee' ? 'active' : ''} onClick={() => setCurrentTab('internal-committee')}>INTERNAL COMMITEE</a>
          </div>
        </div>
      </div>
      <div className='p-2'></div>
      <div className='container'>
        <div className='p-1'></div>
        <LeftHyperlinkButton text='Go Back' href='/blog' textColor='#000000' hoverTextColor='#ff0000' />
        <div className='p-1'></div>
        <h1>{currentArticle?.heading}</h1>
        <div className='p-2'></div>
        <div className='d-flex align-items-center gap-3'>
          {isNew ? <NewLabel /> : <></>}<span>{currentArticle?.createdAt}</span>
        </div>
        <div className='p-2'></div>
        <img src={mediaUrl(currentArticle?.image[0]._id)} style={{ width: '100%', maxWidth: '100%' }} />
        <div className='p-2'></div>
        <div className='d-flex gap-3'>
          {/* <div className='d-flex flex-column gap-2 align-items-center'>
            <a href='#'><img src={shareIcon}/></a>
            <div style={{ backgroundColor: 'lightblue', borderRadius: '3px', width: 45, height: 45 }}></div>
            <div>Share</div>
          </div> */}
          <div className='d-flex flex-column gap-2 align-items-center'>
            <div className='div-button' onClick={e => navigator.clipboard.writeText(currentArticle.shareableLink)}><img src={linkIcon}/></div>
            {/* <div style={{ backgroundColor: 'lightblue', borderRadius: '3px', width: 45, height: 45 }}></div> */}
            <div>Copy Link</div>
          </div>
        </div>
        <div className='p-2'></div>
        {currentArticle?.body.split('\n').map((para, i) => (<p key={i}>{para}</p>))}
        <div className='p-3'></div>
        <h4 className='text-danger'>Sources</h4>
        <div className='p-2'></div>
        {currentArticle?.sources.map(sourceLink  => (<div>
        <a target='_blank' href={sourceLink} className='text-danger small-text'>{sourceLink}</a>
        </div>))}
        <div className='p-3'></div>
      </div>
      <div className='p-1'></div>
      <div style={{ backgroundColor: 'rgba(13, 19, 26, 0.02)' }}>
        <div className='p-3'></div>
        <div className='container'>
          <div className='d-flex justify-content-between'>
            <h3>Latest Posts</h3>
            <div>
              <span onClick={(e) => previousPage()} className='hyperlink-span'>&lt;</span>
              <span>&nbsp;&nbsp;</span>
              <span onClick={(e) => nextPage()} className='hyperlink-span'>&gt;</span>
            </div>
          </div>
          <div className='p-2'></div>
          <div className='row'>
            {latestArticles.filter((article, i) => i >= currentIndex && i <= currentIndex + 3).map((article, i) => <div key={i} className='col-sm-3'>
              <img src={mediaUrl(article.image[0]._id)} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-2'></div>
              <div style={{ fontSize: 'smaller' }}>{article.createdAt} | 3 min read</div>
              <div className='p-2'></div>
              <a href={`/blog/${article.slug}`} className='text-link'>
                <h5>{article.heading}</h5>
                {/* <div>{article.body.substring(0, 70).split(' ').slice(0, -1).join(' ') + ' ...'}</div> */}
              </a>
              <div className='p-3'></div>
            </div>)}
          </div>
        </div>
        <div className='p-3'></div>
      </div>
      <Footer backgroundColor='#0D131A' textColor='white' />
      <ChatPopup></ChatPopup>
    </div>
  )
}

export default Article