const axios = require('axios').default;

const getAllPages = async () => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/page/all`);
  }
  catch (err) {
    console.log('Fetching pages failed');
    return null;
  }
}
const getPageBySlug = async (slug) => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/page/slug/${slug}`);
  }
  catch (err) {
    console.log('Fetching page details failed');
    return null;
  }
}

export { getPageBySlug, getAllPages };
