import React from 'react'
import './hamburger-icon.css'

const HamburgerIcon = () => {
  return (
    <div style={{width: '40px', height: '40px', gap: '7px'}} className='d-flex flex-column align-items-end justify-content-center'>
      <div className='line1'></div>
      <div className='line2'></div>
    </div>
  )
}

export default HamburgerIcon