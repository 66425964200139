import React, { useState } from 'react'
import './left-button.css'
import leftArrow from '../../assets/images/left-arrow.png'

const LeftButton = (props) => {
  let [ textColor, setTextColor ] = useState(props.textColor);
  return (
    <div className='button-container' onMouseOver={(e) => setTextColor(props.hoverTextColor)} onMouseOut={(e) => setTextColor(props.textColor)}>
      <span className='d-flex align-items-center hyperlink' onClick={props.onClick}>
        <img src={leftArrow} className={props.className} />
        <p className='label' style={{ marginBottom: '10px', color: textColor }}>{props.label}</p>
      </span>
    </div>
  )
}

export default LeftButton