import React, { useEffect, useState } from 'react'
import NavBar from '../../components/navbar/NavBar'
import Footer from '../../components/footer/footer'
import { getPageBySlug } from '../../services/pageService';
import { text, mediaByName, mediaUrl } from '../../services/pageFunctions';
import { getAllMembers } from '../../services/memberService';
import RightHyperlinkButton from '../../components/right-hyperlink-button/RightHyperlinkButton';
import { getAllClients } from '../../services/client-service';
import './about.css'
import { Helmet } from 'react-helmet-async';
import ChatPopup from '../../components/chat-popup/ChatPopup';

const About = () => {
  const [page, setPage] = useState(null);
  const [members, setMembers] = useState([]);
  const [clients, setClients] = useState([]);
  let timer = null;

  const shuffleArray = (arr) => {
    arr.sort(() => Math.random() - 0.5)
  };

  const shuffleClients = () => {
    getAllClients().then((result) => {
      let clientsCopy = result.data.data;
      clientsCopy.sort(() => Math.random() - 0.5)
      setClients(clientsCopy);
      clearTimeout(timer);
      timer = setTimeout(shuffleClients, 10000);
    });
  };

  useEffect(() => {
    getPageBySlug('about').then(result => {
      setPage(result.data.data);
    });
    getAllMembers().then(result => {
      setMembers(result.data.data);
    });
    getAllClients().then((result) => {
      setClients(result.data.data);
      timer = setTimeout(shuffleClients, 10000);
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>SASHA | About Us</title>
        <meta name='title' content="PoSH consultation India |  PoSH Act 2013 compliance" />
        <meta name='description' content="We provide customized sensitization & awareness programs for employees, management and IC. India's leading PoSH service provider." />
      </Helmet>
      <div>
        <NavBar />
        <div className='container'>
          <div className='row'>
            <div className='col-sm-5 d-flex align-items-center justify-content-center'>
              <div>
                <p className='text-danger'>{text(page, 0)}</p>
                <h1 className='heading mb-3'>{text(page, 1)}</h1>
                <p>{text(page, 2)}</p>
                <p className='text-danger'>{text(page, 3)}</p>
                <p>{text(page, 4)}</p>
              </div>
            </div>
            <div className='col-sm-7 text-center' style={{ backgroundImage: `url('${mediaByName(page, 'ellipse118')}')`, backgroundRepeat: 'no-repeat' }}>
              <img src={mediaByName(page, 'girls')} className='p-5' style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </div>
      <div className='bg-dark'>
        <div className='pt-5 pb-5'></div>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6 d-flex align-items-center justify-conent-center'>
              <div>
                <div className='row mb-3'>
                  <div className='col-sm-4'>
                    <h1 className='heading text-danger'>{text(page, 5)}</h1>
                    <div className='bg-danger pt-1 pb-1 mt-2 mb-2 w-50'></div>
                    <p className='text-white'>{text(page, 6)}</p>
                  </div>
                  <div className='col-sm-4'>
                    <h1 className='heading text-danger'>{text(page, 7)}</h1>
                    <div className='bg-danger pt-1 pb-1 mt-2 mb-2 w-50'></div>
                    <p className='text-white'>{text(page, 8)}</p>
                  </div>
                  <div className='col-sm-4'>
                    <h1 className='heading text-danger'>{text(page, 9)}</h1>
                    <div className='bg-danger pt-1 pb-1 mt-2 mb-2 w-50'></div>
                    <p className='text-white'>{text(page, 10)}</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>
                    <h1 className='heading text-danger'>{text(page, 11)}</h1>
                    <div className='bg-danger pt-1 pb-1 mt-2 mb-2 w-50'></div>
                    <p className='text-white'>{text(page, 12)}</p>
                  </div>
                  <div className='col-sm-4'>
                    <h1 className='heading text-danger'>{text(page, 13)}</h1>
                    <div className='bg-danger pt-1 pb-1 mt-2 mb-2 w-50'></div>
                    <p className='text-white'>{text(page, 14)}</p>
                  </div>
                  <div className='col-sm-4'>
                    <h1 className='heading text-danger'>{text(page, 15)}</h1>
                    <div className='bg-danger pt-1 pb-1 mt-2 mb-2 w-50'></div>
                    <p className='text-white'>{text(page, 16)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-6 d-flex align-items-center'>
              <div className='row'>
                {clients.filter((client, i) => i < 9).map((client, i) => <div key={i} className='col-sm-4 pt-4 pb-4 d-flex align-items-center justify-content-center'>
                  <div style={{width: '100px', height: '100px', backgroundImage: `url('${mediaUrl(client?.logo[0]._id)}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain'}}>

                  </div>
                </div>)}
              </div>
            </div>
          </div>
        </div>
        <div className='pt-5 pb-5'></div>
      </div>
      <div className='container'>
        <div className='pt-5 pb-5'></div>
        <div className='row'>
          <div className='col-sm-8'>
            <p className='text-danger'>{text(page, 17)}</p>
            <div className='heading'>{text(page, 18)}</div>
            <p>{text(page, 19)}</p>
            <p className='pt-5'>{text(page, 20)}</p>
            <RightHyperlinkButton textColor='#000000' hoverTextColor='#ff0000' text={text(page, 21)} href='/services' fontWeight='bold' />
          </div>
          <div className='col-sm-4'>
            <img src={mediaByName(page, 'hammer')} style={{ maxWidth: '100%', backgroundImage: `url('${mediaByName(page, 'ellipse115')}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center bottom' }} />
          </div>
        </div>
        <div className='pt-5 pb-5'></div>
      </div>
      <div className='container'>
        <div className='pt-5 pb-5'></div>
        <div className='row'>
          <div className='col-sm-7 text-center' style={{ backgroundImage: `url('${mediaByName(page, 'ellipse116')}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center center' }}>
            <img src={mediaByName(page, 'vision')} style={{ maxWidth: '100%' }} />
          </div>
          <div className='col-sm-5'>
            <p className='text-danger'>{text(page, 22)}</p>
            <div className='heading'>{text(page, 23)}</div>
            <p className='pt-4'>{text(page, 24)}</p>
          </div>
        </div>
        <div className='pt-5 pb-5'></div>
      </div>
      <div className='container'>
        <div className='pt-5 pb-5'></div>
        <div className='row'>
          <div className='col-sm-6'>
            <p className='text-danger'>{text(page, 25)}</p>
            <div className='heading'>{text(page, 26)}</div>
            <p><strong>{text(page, 27)}</strong></p>
          </div>
          <div className='col-sm-6 text-center' style={{ backgroundImage: `url('${mediaByName(page, 'ellipse117')}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'left center' }}>
            <img src={mediaByName(page, 'mission')} style={{ maxWidth: '100%' }} />
          </div>
        </div>
        <div className='pt-5 pb-5'></div>
      </div>
      <div className='bg-dark'>
        <div className='pt-5 pb-5'></div>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6 text-white'>
              <div className='heading'>{text(page, 28)} <span className='text-danger'>{text(page, 29)}</span> {text(page, 30)}</div>
              <p>{text(page, 31)}</p>
              <div>
                <ul>
                  <li>{text(page, 32)}</li>
                  <li>{text(page, 33)}</li>
                  <li>{text(page, 34)}</li>
                </ul>
              </div>
            </div>
            <div className='col-sm-6 text-center pt-5' style={{ backgroundImage: `url('${mediaByName(page, 'circleRed1')}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
              <img src={mediaByName(page, 'pledge')} style={{ maxWidth: '100%' }} />
            </div>
          </div>
        </div>
        <div className='pt-5 pb-5'></div>
      </div>
      <div className='test' style={{ backgroundColor: '#FCD6D3' }}>
        <div className='pt-5 pb-5'></div>
        <div className='container'>
          <div className='text-danger'>{text(page, 35)}</div>
          <div className='heading'>{text(page, 36)}</div>
          <p>{text(page, 37)}</p>
          <p><strong className='text-danger'>{text(page, 38)}</strong></p>
          <p>{text(page, 39)}</p>
          <div className='p-2'></div>
          <div className='row'>
            {members.filter((member, i) => i < 8).map(member => (<div className='col-sm-3 mb-4'>
              <div className='rounded pt-5 pb-5 bg-white d-flex align-items-center justify-content-center flex-column'>
                <div className='profile-image-container' style={{ backgroundImage: `url('${mediaUrl(member.smallImage[0]._id)}')` }}>
                </div>
                <div className='heading mt-2 text-center' style={{ fontSize: '1.3em' }}>{member.position}</div>
                <a href={`/profile/${member.nickname}`} className='mb-2 text-danger' style={{ fontSize: '1em' }}>View Profile</a>
                <a href={member.linkedInLink} target='_blank'>
                  <div className='d-flex text-secondary align-items-center' style={{ fontSize: 'small' }}>
                    <img src={mediaByName(page, 'linkedinSmall')} />
                    <div className='ps-2' style={{ fontSize: '1.1em' }}>LinkedIn</div>
                  </div>
                </a>
              </div>
            </div>))}
          </div>
          <RightHyperlinkButton textColor='#000000' hoverTextColor='#ff0000' text='VIEW ALL' href='/profile/Kanti' fontWeight='bold' />
        </div>
        <div className='pt-5 pb-5'></div>
      </div >
      <div className='container'>
        <div className='pt-5 pb-5'></div>
        <div className='row' style={{ backgroundImage: `url('${mediaByName(page, 'ellipse119')}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right bottom' }}>
          <div className='col-sm-5 d-flex align-items-center justify-content-center'>
            <div>
              <p className='text-danger'>{text(page, 41)}</p>
              <div className='heading'>{text(page, 42)}</div>
              <p><strong>{text(page, 43)}</strong></p>
              <div className='pt-5'>{text(page, 44)}</div>
              <RightHyperlinkButton textColor='#000000' hoverTextColor='#ff0000' text={text(page, 45)} href='/learning-center' fontWeight='bold' />
            </div>
          </div>
          <div className='col-sm-7 d-flex align-items-center justify-content-center pt-5' style={{ backgroundImage: `url('${mediaByName(page, 'circleRed2')}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
            <img src={mediaByName(page, 'learningCenter1')} style={{ maxWidth: '100%' }} />
          </div>
        </div>
        <div className='pt-5 pb-5'></div>
      </div>
      <div className='container' style={{ backgroundImage: `url('${mediaByName(page, 'ellipse120')}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center bottom' }}>
        <div className='pt-5 pb-5'></div>
        <div className='row'>
          <div className='col-sm-7'>
            <img src={mediaByName(page, 'contactUs1')} style={{ maxWidth: '100%' }} />
          </div>
          <div className='col-sm-5'>
            <div className='text-danger'>{text(page, 46)}</div>
            <div className='heading pb-4'>{text(page, 47)}</div>
            <p>{text(page, 48)}</p>
          </div>
        </div>
        <div className='container mt-5 bg-dark rounded text-white pt-5 ps-5 pe-5 pb-3'>
          <div className='row'>
            <div className='col-sm-4 mb-4 d-flex justify-content-center'>
              <div>
                <div>
                  <img src={mediaByName(page, 'mailIcon')} /><span className='ms-2 text-white'>Kanti Joshi</span>
                </div>
                <div>Kanti@sashaindia.com</div>
              </div>
            </div>
            <div className='col-sm-4 mb-4 d-flex justify-content-center'>
              <div>
                <div>
                  <img src={mediaByName(page, 'phoneIcon')} /><span className='ms-2 text-white'>Kanti Joshi</span>
                </div>
                <div>+91 98450 96503</div>
              </div>
            </div>
            <div className='col-sm-4 mb-4 d-flex justify-content-center'>
              <div>
                <div>
                  <img src={mediaByName(page, 'locationIcon')} /><span className='ms-2 text-white'>SASHA</span>
                </div>
                <div>Bangalore</div>
              </div>
            </div>
          </div>
        </div>
        <div className='pt-5 pb-5'></div>
      </div>
      <Footer backgroundColor='#000000' textColor='#ffffff' />
      <ChatPopup></ChatPopup>
    </div >
  )
}

export default About