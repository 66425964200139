const axios = require('axios').default;

const getArticleBySlug = async (slug) => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/blog-article/slug/${slug}`);
  }
  catch(err) {
    console.log('Fetching blog-article details failed.');
    return null;
  }
}

const getLatestArticles = async() => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/blog-article/latest`);
  }
  catch(err) {
    console.log('Fetching blog-article details failed.');
    return null;
  }
}

const getFeatured = async() => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/blog-article/featured`);
  }
  catch(err) {
    console.log(`Fetching featured article failed`);
    return null;
  }
}

const getArticleByCategory = async(category) => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/blog-article/category/${category}`);
  }
  catch(err) {
    console.log(`Fetching article by category failed`);
    return null;
  }
}

export { getArticleBySlug, getLatestArticles, getFeatured, getArticleByCategory };
