const axios = require('axios').default;

const getEventById = async (id) => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/event/${id}`);
  }
  catch(err) {
    console.log('Fetching event details failed.');
    return null;
  }
}

const getLatestEvents = async() => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/event/latest`);
  }
  catch(err) {
    console.log('Fetching event details failed.');
    return null;
  }
}

const getFeaturedEvent = async() => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/event/featured`);
  }
  catch(err) {
    console.log('Fetching event details failed.');
    return null;
  }
}

const register = async(eventId, name, email, contactNumber) => {
  try {
    return axios.put(`${process.env.REACT_APP_API_ENDPOINT}/event/register`, {
      eventId,
      name,
      email,
      contactNumber
    });
  }
  catch(err) {
    console.log('Registration for event failed.');
    return null;
  }
}

export { getEventById, getLatestEvents, getFeaturedEvent, register };
