const axios = require('axios').default;

const createMessage = async (messageData) => {
  try {
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/message`, messageData);
  }
  catch(err) {
    console.log('Saving message failed.');
    return null;
  }
}

export { createMessage };
