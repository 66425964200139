import React, { useEffect, useRef, useState } from 'react'
import Footer from '../../components/footer/footer'
import NavBar from '../../components/navbar/NavBar'
import './learning-center.css'
import { Alert, Box, Chip, Snackbar, Tab, Tabs } from '@mui/material'
import RightButton from '../../components/right-button/RightButton'
import { mediaByName, mediaUrl, text } from '../../services/pageFunctions'
import { getPageBySlug } from '../../services/pageService'
import { getLatestLegalResources, getLegalResourceBySlug } from '../../services/legalResourceService'
import { formatDate, formatDateString, monthName } from '../../services/dateUtil'
import { getFeaturedEvent, getLatestEvents } from '../../services/eventService'
import { getClipped, getParagraphs } from '../../services/textUtil'
import { useParams } from 'react-router-dom'
import { sleep } from '../../services/miscUtil'
import RightHyperlinkButton from '../../components/right-hyperlink-button/RightHyperlinkButton'
import LeftHyperlinkButton from '../../components/left-hyperlink-button/LeftHyperlinkButton'
import { getAllLinks } from '../../services/linkService'
import RegisterDialog from '../../components/register-dialog/register-dialog'
import { getAllPosters } from '../../services/poster-service'
import LeftButton from '../../components/left-button/LeftButton'
import DownloadButton from '../../components/download-button/download-button'
import ShareButton from '../../components/share-button/share-button'
import { Helmet } from 'react-helmet-async'
import ChatPopup from '../../components/chat-popup/ChatPopup'

const LearningCenter = () => {
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(null);
  const [resources, setResources] = useState([]);
  const [showIndividual, setShowIndividual] = useState(false);
  const [currentResource, setCurrentResource] = useState(null);
  const [featuredEvent, setFeaturedEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [resourceStartIndex, setResourceStartIndex] = useState(0);
  const [resourceEndIndex, setResourceEndIndex] = useState(0);
  const [links, setLinks] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [eventForRegistration, setEventForRegistration] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveFailed, setSaveFailed] = useState(false);
  const [posterListing, setPosterListing] = useState(false);
  const [posterDisplay, setPosterDisplay] = useState(false);
  const [posters, setPosters] = useState([]);
  const [currentPoster, setCurrentPoster] = useState(null);

  const darkDivRef = useRef(null);
  const { slug } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showFirstResource = (event) => {
    darkDivRef.current.scrollIntoView();
    setShowIndividual(true);
    setCurrentResource(resources[0]);
  }

  const showResourceById = (id) => {
    setShowIndividual(true);
    setCurrentResource(resources.filter(resource => resource._id == id)[0]);
    darkDivRef.current.scrollIntoView();
  }

  const previousPage = () => {
    if (resourceStartIndex !== 0) {
      setResourceStartIndex(resourceStartIndex - 4);
    }
  }

  const nextPage = () => {
    if (resourceStartIndex + 3 < resources.length - 1) {
      setResourceStartIndex(resourceStartIndex + 4);
    }
  }
  const showRegistrationDialog = (event) => {
    // Show the registration dialog
    setEventForRegistration(event);
    setOpenDialog(true)
  }

  const handleClose = () => {
    setSaveSuccess(false);
    setSaveFailed(false);
  }

  const onSuccess = () => {
    setSaveSuccess(true);
    setOpenDialog(false);
  }

  const onFailure = () => {
    setSaveFailed(true);
    setOpenDialog(false);
  }

  const showPosterListing = async () => {
    setPosterListing(true);
    darkDivRef.current.scrollIntoView();
  }

  const showSinglePoster = (poster) => {
    setPosterListing(false)
    setPosterDisplay(true)
    setCurrentPoster(poster)
    darkDivRef.current.scrollIntoView();
  }

  useEffect(() => {
    const fn = async () => {
      let results = await Promise.all([
        getPageBySlug('learning-center'),
        getLatestLegalResources(),
        getFeaturedEvent(),
        getLatestEvents(),
        getAllLinks(),
        getAllPosters()
      ]);
      setPage(results[0].data.data);
      results[1].data.data.forEach(obj => formatDate(obj));
      setResources(results[1].data.data);
      setResourceStartIndex(0);
      setResourceEndIndex(results[1].data.data.length - 1);
      results[2].data.data[0].startDate = formatDateString(results[2].data.data[0].startDate);
      results[2].data.data[0].endDate = formatDateString(results[2].data.data[0].endDate);
      setFeaturedEvent(results[2].data.data[0]);
      setEvents(results[3].data.data.map(event => {
        return {
          ...event,
          day: parseInt(event.startDate.split('T')[0].split('-')[2]) + 1,
          month: monthName(parseInt(event.startDate.split('T')[0].split('-')[1])),
          startDate: formatDateString(event.startDate),
          endDate: formatDateString(event.endDate)
        }
      }));
      setLinks(results[4].data.data);
      setPosters(results[5].data.data);
      if (slug && slug !== 'legal-resources') {
        let result = await getLegalResourceBySlug(slug);
        setCurrentResource(result.data.data[0]);
        setShowIndividual(true);
        await sleep(250)
        darkDivRef.current.scrollIntoView();
      }
      else {
        await sleep(250)
        darkDivRef.current.scrollIntoView();
      }
    };
    fn();
  }, [])

  return (
    <div>
      <Helmet>
        <title>SASHA | Learning Center</title>
        <meta name='title' content="Sexual Harassment Posters | PoSH Guide | PoSH resources" />
        <meta name='description' content="Create a sexual harassment-free workplace - Complete guide for HR, IC and management. Employee awareness. PoSH compliance." />
      </Helmet>
      <NavBar />
      <Snackbar open={saveSuccess} key={1} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Registred for event successfully.
        </Alert>
      </Snackbar>
      <Snackbar open={saveFailed} key={2} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Could not register for event. Please write to us on our email.
        </Alert>
      </Snackbar>
      <RegisterDialog onSuccess={onSuccess} onFailure={onFailure} event={eventForRegistration} open={openDialog} close={() => setOpenDialog(false)} />
      <div className='container'>
        <div className='p-3'></div>
        <div className='row'>
          <div className='col-sm-6 d-flex align-items-center'>
            <div>
              <div className='text-danger'>{text(page, 0)}</div>
              <div className='heading'>{text(page, 1)}</div>
              {text(page, 2)}
            </div>
          </div>
          <div className='col-sm-6'>
            <img src={mediaByName(page, 'floatingGirl')} style={{ maxWidth: '100%' }} />
          </div>
        </div>
        <div className='p-3'></div>
      </div>
      <div ref={darkDivRef}></div>
      <div className='bg-dark'>
        <div className='container'>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label='LEGAL RESOURCES' className='text-white' />
              <Tab label='CREATIVE RESOURCES' className='text-white' />
              <Tab label='EVENTS' className='text-white' />
            </Tabs>
          </Box>
        </div>
        <div className='container text-white'>
          <div role='tabpanel' hidden={value != 0}>
            <div className='p-3'></div>
            {showIndividual && currentResource ? <>
              <LeftHyperlinkButton textColor='#ffffff' hoverTextColor='#ff0000' text='All Legal Resources' href={`/learning-center/legal-resources`} />
              <div className='p-2'></div>
              <h1>{currentResource?.name}</h1>
              <div>{currentResource.createdAt}</div>
              <div className='p-2'></div>
              <img src={mediaUrl(currentResource.image[0]._id)} style={{ maxWidth: '100%' }} />
              <div className='p-2'></div>
              {getParagraphs(currentResource.description)}
              <div className='p-2'></div>
              <div>Sources</div>
              <div className='p-2'></div>
              <ul>
                {currentResource.links ? currentResource.links.split('\n').map((sourceLink, j) => (<li><a href={sourceLink} target='_blank'>{sourceLink}</a></li>)) : <></>}
              </ul>
            </> : <>
              {resources[0] ? <div className='row'>
                <div className='col-sm-6'>
                  <h1>{resources[0] ? resources[0].name : ''}</h1>
                  <div>{resources[0] ? resources[0].createdAt : ''}</div>
                  <span className='text-primary'>{resources[0] ? resources[0].resourceType : ''}</span>
                  <div className='p-2'></div>
                  <p className='text-justify'>{resources[0] ? getClipped(resources[0].description, 70) : ''}</p>
                  <RightHyperlinkButton textColor='#ffffff' hoverTextColor='#ff0000' text='Read' href={`/learning-center/${resources[0].slug}`} />
                </div>
                <div className='col-sm-6'>
                  <img src={resources[0] ? mediaUrl(resources[0].image[0]._id) : ''} style={{ maxWidth: '100%' }} />
                </div>
              </div> : <></>}</>}
            <div className='p-3'></div>
            <div className='d-flex align-items-center justify-content-between'>
              <h3>Resources Collection</h3>
              <div>
                <span onClick={(e) => previousPage()} className='hyperlink-span'>&lt;</span>
                <span>&nbsp;&nbsp;</span>
                <span onClick={(e) => nextPage()} className='hyperlink-span'>&gt;</span>
              </div>
            </div>
            <div className='p-2'></div>
            <div className='row'>
              {resources.filter((resource, i) => i >= resourceStartIndex && i <= resourceStartIndex + 3).map((resource, i) => (<a key={i} className='col-sm-3 resource-link' href={`/learning-center/${resource.slug}`}>
                <img src={mediaUrl(resource.image[0]._id)} style={{ width: '100%' }} />
                <div className='p-1'></div>
                <p>{resource.name}</p>
                <div>{resource.createdAt}</div>
                <div className='p-2'></div>
              </a>))}
            </div>
          </div>
          <div role='tabpanel' hidden={value != 1}>
            <div className='p-3'></div>
            {!posterListing && !posterDisplay ? <><div className='row'>
              <div className='col-sm-6'>
                <h1>Newsletter</h1>
                <div className='p-2'></div>
                <p>VOICE - The SASHA Newsletter publishes articles authored by SASHA members and other prominent individuals on topics like legal updates, case law analysis, compliance aspects, social issues related to PoSH etc.</p>
                <div className='p-2'></div>
                <RightHyperlinkButton text='Voice SASHA - Substack' href={links.length > 0 ? links.filter(link => link.label == 'Substack')[0].target : '#'} textColor='#ffffff' hoverTextColor='#ff0000' target='_blank' />
              </div>
              <div className='col-sm-6'>
                <img src={mediaByName(page, 'newsletter')} style={{ maxWidth: '100%' }} />
                <div className='p-1'></div>
                <div className='d-flex align-items-center gap-2'>
                  <Chip color='error' label='New' className='ps-3 pe-3' />
                  <span className='text-white'>Issue 21</span>
                  <span className='text-white'>|</span>
                  <span className='text-white'>12 September 2022</span>
                </div>
              </div>
            </div>
              <div className='p-3'></div>
              <div className='row'>
                <div className='col-sm-6'>
                  <h1>Posters</h1>
                  <div className='p-2'></div>
                  <p>Download our free ready-to-print posters that spread awareness on sexual harassment at the workplace and PoSH. We're sure the quirk will stay on and do wonders as a subtle reminder at your workplace.</p>
                  <div className='p-2'></div>
                  <RightButton textColor='#ffffff' hoverTextColor='#ff0000' label='View all' onClick={(e) => showPosterListing()} />
                </div>
                <div className='col-sm-6'>
                  <img src={mediaByName(page, 'poster')} style={{ maxWidth: '100%' }} />
                  <div className='p-1'></div>
                  <div className='d-flex align-items-center gap-2'>
                    <Chip color='error' label='New' className='ps-3 pe-3' />
                    <span className='text-white'>2022 Edition</span>
                  </div>
                </div>
              </div>
              <div className='p-3'></div>
              <div className='row'>
                <div className='col-sm-6'>
                  <h1>PoSHcast</h1>
                  <div className='p-2'></div>
                  <p>VOICE - The SASHA PoSHCast is your go to podcast for all things PoSH. Each month, we explore topics beyond the legal mandate of PoSH by interacting with leading PoSH experts, legal consultants, HR personnel and more.</p>
                  <div className='p-2'></div>
                  <div><a target='_blank' href={links.length > 0 ? links.filter(link => link.label == 'Spotify')[0].target : '#'}>Spotify</a></div>
                  <div><a target='_blank' href={links.length > 0 ? links.filter(link => link.label == 'Apple Podcast')[0].target : '#'}>Apple Podcast</a></div>
                  <div><a target='_blank' href={links.length > 0 ? links.filter(link => link.label == 'Soundcloud')[0].target : '#'}>Soundcloud</a></div>
                  <div><a target='_blank' href={links.length > 0 ? links.filter(link => link.label == 'Youtube')[0].target : '#'}>Youtube</a></div>
                </div>
                <div className='col-sm-6'>
                  <img src={mediaByName(page, 'poshcast')} style={{ maxWidth: '100%' }} />
                  <div className='p-1'></div>
                  <div className='d-flex align-items-center gap-2'>
                    <Chip color='error' label='New' className='ps-3 pe-3' />
                    <span className='text-white'>Voice-The Sasha PoSHcast</span>
                    <span className='text-white'>|</span>
                    <span className='text-danger'>Episode 13</span>
                  </div>
                </div>
              </div></> : posterListing && !posterDisplay ? <>
                <LeftButton textColor='#ffffff' hoverTextColor='#ff0000' onClick={(e) => setPosterListing(false)} label='Go Back' />
                <div className='p-2'></div>
                <h2 className='text-white'>Posters</h2>
                <div className='p-2'></div>
                <div className='row'>
                  {posters.map(poster => <div className='col-sm-2 mb-4'>
                    <div>
                      <img className='image-with-pointer' src={mediaUrl(poster.previewImage[0]._id)} style={{ maxWidth: '100%' }} onClick={(e) => { showSinglePoster(poster); console.log('Clicked'); }} />
                      <div className='p-1'></div>
                      <DownloadButton onClick={() => { }} />
                      &nbsp;
                      <ShareButton onClick={() => { }} />
                    </div>
                  </div>)}
                </div>
              </> : !posterListing && posterDisplay ? <>
                <LeftButton textColor='#ffffff' hoverTextColor='#ff0000' onClick={(e) => { setPosterListing(true); setPosterDisplay(false) }} label='Go Back' />
                <div className='p-2'></div>
                <h2 className='text-white'>{currentPoster.name}</h2>
                <div className='p-2'></div>
                <img src={mediaUrl(currentPoster.largeImage[0]._id)} style={{ maxWidth: '100%' }} />
              </> : <></>}
            <div className='p-3'></div>
          </div>
          <div role='tabpanel' hidden={value != 2}>
            <div className='p-3'></div>
            {featuredEvent ? <div className='row'>
              <div className='col-sm-6'>
                <h1>{featuredEvent.title}</h1>
                <div className='p-2'></div>
                <h4>{featuredEvent.subtitle}</h4>
                <div className='p-2'></div>
                <p className='text-primary'>{featuredEvent.type}</p>
                <div className='p-2'></div>
                <p>{featuredEvent.description}</p>
                <RightButton onClick={(e) => showRegistrationDialog(featuredEvent)} label='Register' textColor='#ffffff' hoverTextColor='#ff0000' />
              </div>
              <div className='col-sm-6'>
                <img src={mediaUrl(featuredEvent.image[0]._id)} style={{ maxWidth: '100%' }} />
                <div className='p-2'></div>
                <div className='d-flex align-items-center'>
                  <div>{featuredEvent.startDate} {featuredEvent.startTime} - {featuredEvent.endDate} {featuredEvent.endTime}</div>
                </div>
                {/* <div className='p-2'></div>
                <h5>Guests</h5>
                <span>Kanti Joshi</span>, <span>Jessamine Mathew</span> */}
              </div>
            </div> : <></>}
            <div className='p-4'></div>
            <Box sx={{ borderBottom: 1, borderColor: 'red' }} className='pb-3'>
              <h3>Upcoming Events</h3>
            </Box>
            {events.length > 0 ? events.map(event => <div>
              <div className='p-5'></div>
              <div className='row'>
                <div className='col-sm-3'>
                  <h1>{event.day}</h1>
                  <h5>{event.month}</h5>
                </div>
                <div className='col-sm-6'>
                  <p>{event.title}</p>
                  <p>{event.subtitle}</p>
                  <p className='event-type'>{event.type}</p>
                  <div>{event.startDate} {event.startTime} - {event.endDate} {event.endTime}</div>
                </div>
                <div className='col-sm-3'>
                  <RightButton onClick={(e) => showRegistrationDialog(event)} label='Register' textColor='#ffffff' hoverTextColor='#ff0000' />
                </div>
              </div>
            </div>) : <div></div>}
            <div className='p-3'></div>
          </div>
        </div>
        <div className='p-5'></div>
      </div>
      <Footer backgroundColor='#0D131A' textColor='white' />
      <ChatPopup></ChatPopup>
    </div>
  )
}

export default LearningCenter