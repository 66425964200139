import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import Footer from '../../components/footer/footer';
import NavBar from '../../components/navbar/NavBar';
import { mediaByName, text } from '../../services/pageFunctions';
import { getPageBySlug } from '../../services/pageService';
import './e-module.css';
import ChatPopup from '../../components/chat-popup/ChatPopup'

const EModule = () => {
  const [page, setPage] = useState(null);

  useEffect(() => {
    const fn = async () => {
      let result = await getPageBySlug('e-module');
      setPage(result.data.data);
    };
    fn();
  }, []);

  return (
    <div>
      <Helmet>
        <title>SASHA | e-Module</title>
      </Helmet>
      <div>
        <NavBar />
      </div>
      <div className='container'>
        <div className='p-3'></div>
        <h1 className='heading'>{text(page, 0)}</h1>
        <div className='p-3'></div>
        <div>
          <img src={mediaByName(page, 'e-module')} style={{ maxWidth: '100%', float: 'right', margin: '0px 0px 20px 20px' }} />
          {page?.textEntries.filter((text, i) => i > 0).map(textEntry => <ReactMarkdown children={textEntry.text} components={{
            p: ({ children }) => <p className='generated'>{children}</p>,
            h1: ({ children }) => <h1 className='heading'>{children}</h1>
          }} />)}
        </div>
        <div className='p-3'></div>
      </div>
      <div className='p-3'></div>
      <Footer backgroundColor='#000000' textColor='#ffffff' />
      <ChatPopup></ChatPopup>
    </div>
  )
}

export default EModule