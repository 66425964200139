import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer/footer'
import NavBar from '../../components/navbar/NavBar'
import './blog.css'
import RightButton from '../../components/right-button/RightButton'
import { getArticleByCategory, getFeatured, getLatestArticles } from '../../services/blogService'
import { mediaUrl } from '../../services/pageFunctions'
import { getLatestLegalResources } from '../../services/legalResourceService'
import { formatDate, formatDateString } from '../../services/dateUtil'
import { getLatestEvents } from '../../services/eventService'
import { useParams } from 'react-router-dom'
import RightButtonWithLabel from '../../components/right-button-with-label/right-button-with-label'
import RightHyperLinkButton from '../../components/right-hyperlink-button/RightHyperlinkButton';
import RegisterDialog from '../../components/register-dialog/register-dialog'
import { Alert, Snackbar } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import ChatPopup from '../../components/chat-popup/ChatPopup'

const Blog = () => {
  const [currentTab, setCurrentTab] = useState('recent');
  const [featured, setFeatured] = useState(null);
  const [articles, setArticles] = useState([]);
  const [legalResources, setLegalResources] = useState([]);
  const [latestEvent, setLatestEvent] = useState(null);
  const { category } = useParams();
  let [openDialog, setOpenDialog] = useState(false);
  let [eventForRegistration, setEventForRegistration] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveFailed, setSaveFailed] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const tabs = {
    'workplace': 'Workplace',
    'policies': 'Polices',
    'law mandates': 'Law Mandates',
    'internal committee': 'Internal Committee'
  };
  const showRegistrationDialog = (event) => {
    // Show the registration dialog
    setEventForRegistration(event);
    setOpenDialog(true)
  }

  const handleClose = () => {
    setSaveSuccess(false);
    setSaveFailed(false);
  }

  const onSuccess = () => {
    setSaveSuccess(true);
    setOpenDialog(false);
  }

  const onFailure = () => {
    setSaveFailed(true);
    setOpenDialog(false);
  }

  const previousPage = () => {
    if (currentIndex - 4 >= 0) {
      setCurrentIndex(currentIndex - 4);
    }
  }

  const nextPage = () => {
    if (currentIndex + 4 <= articles.length - 1) {
      setCurrentIndex(currentIndex  + 4);
    }
  }

  useEffect(() => {
    getFeatured().then(result => {
      formatDate(result.data.data[0]);
      setFeatured(result.data.data[0]);
    });
    getLatestLegalResources().then(result => {
      result.data.data.forEach(legalResource => formatDate(legalResource));
      setLegalResources(result.data.data);
    });
    getLatestEvents().then(result => {
      result.data.data[0].startDate = formatDateString(result.data.data[0].startDate);
      result.data.data[0].endDate = formatDateString(result.data.data[0].endDate);
      setLatestEvent(result.data.data[0]);
    });
    if (!category) {
      setCurrentTab('recent');
      getLatestArticles().then(result => {
        setArticles(result.data.data);
      });
    }
    else {
      setCurrentTab(category);
      console.log(category);
      getLatestArticles().then(result => {
        result.data.data.forEach(article => {
          console.log(article.category.map(cat => cat.toLowerCase()));
        })
        setArticles(result.data.data.filter(article => article.category.map(cat => cat.toLowerCase()).includes(category.toLowerCase())));
      });
      // getArticleByCategory(category).then(result => {
      //   setArticles(result.data.data);
      // });
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>SASHA | Blog</title>
        <meta name='title' content="PoSH Law | Create a healthy & safe work environment" />
        <meta name='description' content="Safe, inclusive, sexual harassment-free workplace. All things HR professionals and IC members need to know about PoSH Act 2013." />
      </Helmet>
      <NavBar />
      <Snackbar open={saveSuccess} key={1} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Registred for event successfully.
        </Alert>
      </Snackbar>
      <Snackbar open={saveFailed} key={2} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Could not register for event. Please write to us on our email.
        </Alert>
      </Snackbar>
      <RegisterDialog onSuccess={onSuccess} onFailure={onFailure} event={eventForRegistration} open={openDialog} close={() => setOpenDialog(false)} />
      <div className='bg-dark'>
        <div className='container' style={{ overflowX: 'auto' }}>
          <div className='text-white top-nav'>
            <a href="/blog" className={currentTab === 'recent' ? 'active' : ''} onClick={() => setCurrentTab('recent')}>RECENT</a>
            <a href="/blog/category/workplace" className={currentTab === 'workplace' ? 'active' : ''} onClick={() => setCurrentTab('workplace')}>WORKPLACE</a>
            <a href="/blog/category/policies" className={currentTab === 'policies' ? 'active' : ''} onClick={() => setCurrentTab('policies')}>POLICIES</a>
            <a href="/blog/category/law mandates" className={currentTab === 'law-mandates' ? 'active' : ''} onClick={() => setCurrentTab('law mandates')}>LAW MANDATES</a>
            <a href="/blog/category/internal committee" className={currentTab === 'internal-committee' ? 'active' : ''} onClick={() => setCurrentTab('internal committee')}>INTERNAL COMMITEE</a>
          </div>
        </div>
      </div>
      <div className='p-5'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-6'>
            <h1>{featured?.heading}</h1>
            <div className='p-2'></div>
            <div>{featured?.createdAt}</div>
            <div className='p-2'></div>
            <p>{featured?.body.substring(0, 350).split(' ').slice(0, -1).join(' ') + ' ...'}</p>
            <div className='p-2'></div>
            <RightHyperLinkButton text='Read' href={'/blog/' + featured?.slug} textColor='#000000' hoverTextColor='#ff0000' />
            <div className='p-2'></div>
          </div>
          <div className='col-sm-6'>
            <img src={mediaUrl(featured?.image[0]._id)} style={{ maxWidth: '100%' }} />
          </div>
        </div>
      </div>
      <div className='p-5'></div>
      <div style={{ backgroundColor: 'rgba(13, 19, 26, 0.02)' }}>
        <div className='p-3'></div>
        <div className='container'>
          <div className='d-flex justify-content-between'>
            <h3>{category ? `Posts related to ${tabs[category]}` : 'Latest posts'}</h3>
            <div>
              <span onClick={(e) => previousPage()} className='hyperlink-span'>&lt;</span>
              <span>&nbsp;&nbsp;</span>
              <span onClick={(e) => nextPage()} className='hyperlink-span'>&gt;</span>
            </div>
          </div>
          <div className='p-2'></div>
          <div className='row'>
            {articles.filter((article, i) => i >= currentIndex && i <= currentIndex + 3).map((article, i) => (<div key={i} className='col-sm-3'>
              <img src={mediaUrl(article.image[0]._id)} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-2'></div>
              <div style={{ fontSize: 'smaller' }}>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(Date.parse(article.createdAt))} | {article.approximateReadTime} min read</div>
              <div className='p-2'></div>
              <a href={`/blog/${article.slug}`} className='text-link'>
                <h5>{article.heading}</h5>
                {/* <div>{article.body.substring(0, 70).split(' ').slice(0, -1).join(' ') + ' ...'}</div> */}
              </a>
              <div className='p-3'></div>
            </div>))}
          </div>
        </div>
        <div className='p-3'></div>
      </div>
      <div className='p-3'></div>
      <div className='container'>
        <h5>Latest Legal Resources</h5>
        <div className='p-1' style={{ borderBottom: '1px solid pink' }}></div>
        <div className='p-3'></div>
        <div className='row'>
          {legalResources.filter((resource, i) => i < 4).map((legalResource, i) => (<div key={i} className='col-sm-6 row mb-4'><div className='col-sm-6'>
            <img src={mediaUrl(legalResource.image[0]._id)} style={{ width: '100%', maxWidth: '100%' }} />
          </div>
            <div className='col-sm-6'>
              <div className='small-text'>{legalResource.createdAt}</div>
              <div className='p-1'></div>
              <span className='text-primary small-text' target='_blank'>{legalResource.resourceType}</span>
              <div className='p-1'></div>
              <p>{legalResource.name}</p>
              <RightHyperLinkButton text='Read' href={`/learning-center/${legalResource.slug}`} textColor='#000000' hoverTextColor='#ff0000' />
            </div></div>))}
        </div>
        <div className='p-2'></div>
        <RightHyperLinkButton text='All Legal Resources' href={`/learning-center/legal-resources`} textColor='#000000' hoverTextColor='#ff0000' />
      </div>
      <div className='p-3'></div>
      <div className='upcoming-events-section'>
        <div className='container'>
          <div className='p-3'></div>
          <p>Upcoming Events</p>
          <div className='p-1 red-line mb-4'></div>
          <div className='row'>
            <div className='col-sm-6'>
              <h2>{latestEvent?.name}</h2>
              <div className='p-1'></div>
              <h4>{latestEvent?.title}</h4>
              <div className='text-primary'>{latestEvent?.type}</div>
              <div className='p-1'></div>
              <p>{latestEvent?.description}</p>
              <div className='p-1'></div>
              <RightButton onClick={(e) => showRegistrationDialog(latestEvent)} label='Register' />
            </div>
            <div className='col-sm-6'>
              <img src={mediaUrl(latestEvent?.image[0]._id)} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-1'></div>
              <div className='small-text'>{latestEvent?.startDate}, {latestEvent?.startTime} - {latestEvent?.endDate}, {latestEvent?.endTime}</div>
              <div className='p-1'></div>
            </div>
          </div>
        </div>
        <div className='p-3'></div>
      </div>
      <Footer backgroundColor='#0D131A' textColor='white' />
      <ChatPopup></ChatPopup>
    </div>
  )
}

export default Blog