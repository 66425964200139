const axios = require('axios');

const getAllPosters = async () => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/poster/all`);
  }
  catch (err) {
    console.log('Fetching posters failed');
    return null;
  }
}

export { getAllPosters };
