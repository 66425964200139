import React from 'react'
import './page-not-found.css'
import logo from '../../assets/images/logo.png'
import img404 from '../../assets/images/404.png'
import ChatPopup from '../../components/chat-popup/ChatPopup'

const PageNotFound = () => {
  return (
    <div className='bg-black text-white pb-4 d-flex flex-column' style={{ minHeight: '100vh' }}>
      <div className='container'>
        <div className='d-flex p-3'>
          <a className="navbar-brand" href="#"><img src={logo} style={{ height: '60px' }} /></a>
        </div>
      </div>
      <div style={{ flexGrow: 1 }} className='d-flex align-items-center justify-content-center'>
        <div className='container'>
          <div className='d-flex align-items-center justify-content-center flex-column'>
            <img src={img404} style={{ maxWidth: '100%' }} />
            <div className='heading'>Let's get something Straight</div>
            <p className='mb-3'>The page you were looking for doesn’t exist!</p>
            <p>You’re perhaps here because:</p>
            <ul>
              <li>The page has been moved</li>
              <li>The page no longer exists</li>
              <li>The URL is misspelt</li>
              <li className='text-danger'>You think this page is awesome</li>
            </ul>
            <p className='text-center'>Sigh, we burst that bubble too…</p>
            <p className='text-center'>Now, how about getting you some where useful?</p>
            <div className='text-center'>
              <a href='home' className='pt-2 pb-2 ps-5 pe-5 bg-danger text-white rounded-pill'>Go to Home</a>
            </div>
          </div>
        </div>
      </div>
      <ChatPopup></ChatPopup>
    </div>
  )
}

export default PageNotFound