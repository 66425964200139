import { Alert, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { getAllLinks } from '../../services/linkService';
import RightButton from '../right-button/RightButton';
import './footer.css';
import { addSubscriber } from '../../services/subscriberService';

const Footer = (props) => {
  let [links, setLinks] = useState([]);
  let { handleSubmit, control } = useForm();
  let [registerSuccess, setRegisterSuccess] = useState(false);
  let [registerFailed, setRegisterFailed] = useState(false);
  const onSubscribe = (subData) => {
    console.log('Subscribing...');
    console.log(subData);
    if (subData.email) {
      addSubscriber(subData.email, null).then(result => setRegisterSuccess(true)).catch(error => setRegisterFailed(true));
    }
    else {
      setRegisterFailed(true)
    }
  }
  const handleClose = (e) => {
    console.log('handleClose called')
    setRegisterFailed(false);
    setRegisterSuccess(false);
  }

  useEffect(() => {
    getAllLinks().then(result => {
      setLinks(result.data.data);
    })
  }, []);

  return (
    <div>
      <Snackbar sever open={registerSuccess} key={1} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Your subscription was saved successfully
        </Alert>
      </Snackbar>
      <Snackbar open={registerFailed} key={2} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Subscription failed. You might have already subscribed or haven't provided valid email.
        </Alert>
      </Snackbar>
      <div className='pt-5 pb-5 footer'>
        <div className='container'>
          <div className="row">
            <div className='col-sm-6 d-flex-col' style={{ alignItems: 'center' }}>
              <span style={{ color: `${props.textColor}` }}>Newsletter</span>
              <h5 style={{ color: `${props.textColor}` }}>Want to learn more about</h5>
              <h3 style={{ color: '#ff0000' }}>SEXUAL HARASSMENT?</h3>
              <form className='col-sm-6 mt-5'>
                <div className="mb-3 d-flex gap-2">
                  <Controller control={control} name='email' render={({ field }) => <input type="email" className="form-control p-3" id="exampleFormControlInput1" placeholder="Enter your e-mail ID to subscribe" {...field} />} />
                  <RightButton className='pt-2' onClick={handleSubmit(onSubscribe)} />
                </div>
              </form>
            </div>
            <div className='col-sm-3'>
              <ul className='nav-list'>
                <li><a href='/home' className='footer-link'>Home</a></li>
                <li><a href='/learning-center' className='footer-link'>Learning Center</a></li>
                <li><a href='/about' className='footer-link'>About Us</a></li>
                <li><a href='/contact' className='footer-link'>Contact Us</a></li>
              </ul>
              <ul className='nav-list mt-4'>
                <li><a href='#' className='footer-link'>FAQ's</a></li>
                <li><a href='/privacy-policy' className='footer-link'>Privacy Policy</a></li>
                <li><a href='#' className='footer-link'>Creative Package</a></li>
              </ul>
            </div>
            <div className='col-sm-3'>
              <ul className='nav-list'>
                {links.map((link, i) => (<li key={i}><a target='_blank' className='footer-ext-link' href={link.target}>{link.label}</a></li>))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='sub-footer'>
        <div className='p-2'></div>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-4'>
              <a href='terms-and-conditions' className='footer-link'>Terms &amp; Conditions</a>
            </div>
            <div className='col-sm-4'>
              All rights reserved – 2023 (C) SASHA
            </div>
            <div className='col-sm-4'>
              Website Design  by Intesar Sheikh
            </div>
          </div>
        </div>
        <div className='p-2'></div>
      </div>
    </div>
  )
}

export default Footer