import { Close } from '@mui/icons-material'
import { Alert, Dialog, DialogContent, IconButton, Snackbar, TextField, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { mediaUrl } from '../../services/pageFunctions'
import './register-dialog.css'
import { register } from '../../services/eventService';

const RegisterDialog = (props) => {
    const { control, handleSubmit } = useForm();

    const onSubmit = (data) => {
        register(props.event._id, data.name, data.email, data.contactNumber).then(result => {
            props.onSuccess();
            // setSaveSuccess(true);
        }).catch(error => {
            props.onFailure();
            // setSaveSuccess(false);
        });
    }

    return (
        <Dialog fullWidth={true} maxWidth='md' open={props.open} onClose={props.close}>
            <DialogContent>
                {props.event ? <div>
                    <div className='d-flex justify-content-end'>
                        <Tooltip title='Close'>
                            <IconButton onClick={(e) => props.close()}>
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </div><div className='row'>
                        <div className='col-sm-6'>
                            <div className='p-1'></div>
                            <h3>{props.event.title}</h3>
                            <div>{props.event.subtitle}</div>
                            <div className='text-primary'>{props.event.type}</div>
                            <div>{props.event.description}</div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='mb-2'>
                                        <Controller name='name' control={control} render={({ field }) => <TextField {...field} variant='standard' placeholder='Enter your name' label='Name' fullWidth />} />
                                    </div>
                                    <div className='mb-2'>
                                        <Controller name='email' control={control} render={({ field }) => <TextField {...field} variant='standard' placeholder='Enter email' label='Email' fullWidth />} />
                                    </div>
                                    <div className='mb-2'>
                                        <Controller name='contactNumber' control={control} render={({ field }) => <TextField {...field} variant='standard' placeholder='Enter contact number' label='Contact Number' fullWidth />} />
                                    </div>
                                </div>
                                <div className='p-1'></div>
                                <div className='d-flex justify-content-end'>
                                    <button className='btn btn-danger rounded-pill ps-5 pe-5'>Register</button>
                                </div>
                            </form>
                        </div>
                        <div className='col-sm-6'>
                            <div className='p-2'></div>
                            <img src={mediaUrl(props.event.image[0]._id)} style={{ maxWidth: '100%', width: '100%' }} />
                            <div className='p-1'></div>
                            <div className='small-text'>{props.event.startDate}, {props.event.startTime} - {props.event.endDate}, {props.event.endTime}</div>
                            <div className='p-1'></div>
                        </div>
                    </div>
                </div> : <></>}
            </DialogContent>
        </Dialog>
    )
}

export default RegisterDialog