import React, { useState } from 'react'
import './chat-popup.css'
import chatBot from '../../assets/images/dishabot.png'
import questions from '../../assets/chatbot-questions.json';
import { IconButton, Tooltip } from '@mui/material';
import { Close } from '@mui/icons-material';

const ChatPopup = () => {
  const [conversationOpen, setOpenConversation] = useState(false);
  const [response, setResponse] = useState('')
  const [target, setTarget] = useState('')
  const [responseOpen, setResponseOpen] = useState(false);

  const showResponsePopup = (question) => {
    setOpenConversation(false);
    setResponseOpen(true);
    setResponse(question.answer)
    setTarget(question.target)
  }

  const hideReponse = () => {
    setResponseOpen(false);
  }

  const startConversation = () => {
    setOpenConversation(!conversationOpen);
    hideReponse();
  }

  return (
    <div className='main-container'>
      <div id={'conversation'} className={conversationOpen ? 'chat-conversation' : 'hidden-conversation'}>
        <div style={{ overflow: 'auto', maxHeight: '400px' }}>
          <p>Hi! I am Sasha Bot. Can I help you today?</p>
          <p style={{ fontSize: '14px' }}>
            <ol>
              {questions.map((q, index) => (<li key={index} onClick={() => showResponsePopup(q)} className='chat-link'>{q.question}</li>))}
            </ol>
          </p>
        </div>
      </div>
      <div className={responseOpen ? 'bot-response' : 'bot-response-hidden'}>
        <div className={responseOpen ? 'response' : 'response-hidden'}>
          <div style={{ float: 'right', marginLeft: '10px', marginBottom: '10px' }}>
            <Tooltip title='Close'>
              <IconButton onClick={(e) => hideReponse()}>
                <Close />
              </IconButton>
            </Tooltip>
          </div>
          <div>{response}</div>
          <div style={{marginTop: '20px'}}>
            Click <a href={target}>here</a> for more
          </div>
        </div>
      </div>
      <div className='chat-button' onClick={() => startConversation() }>
        <img src={chatBot} style={{ width: '60px' }} />
      </div>
    </div>
  )
}

export default ChatPopup