const axios = require('axios');

const addSubscriber = async (email, name) => {
  try {
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/subscriber`, {
      email,
      name
    });
  }
  catch (err) {
    console.log('Saving subscription email failed.');
    return null;
  }
}

export { addSubscriber };
