const axios = require('axios');

const getMemberByNickname = async (nickname) => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/team-member/nickname/${nickname}`);
  }
  catch (err) {
    console.log('Fetching team-member details failed');
    return null;
  }
}

const getAllMembers = async () => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/team-member/all`);
  }
  catch (err) {
    console.log(`Fetching all team members failed`);
    return [];
  }
}

export { getMemberByNickname, getAllMembers };
