const axios = require('axios');

const getAllLinks = async () => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/link/all`);
  }
  catch (err) {
    console.log('Fetching links failed');
    return null;
  }
}

const getLinkForLabel = (label, links) => {
  return links.filter(link => link.label === label)[0];
}

export { getAllLinks, getLinkForLabel };
